import * as Sentry from "@sentry/svelte";

/**
 * Reports an error to Sentry without throwing an exception in JavaScript
 * 
 * @param {Error|string} error - The error object or error message to report
 * @param {Object} [context] - Additional context information
 * @param {string} [level="error"] - The Sentry level (error, warning, info, etc.)
 */
export function reportError(error, context = {}, level = "error") {
  if (typeof error === "string") {
    // If it's a string, use captureMessage
    Sentry.captureMessage(error, {
      level,
      extra: context
    });
  } else {
    // If it's an Error object, use captureException
    Sentry.captureException(error, {
      level,
      extra: context
    });
  }
  
  // Optional: Log to console in development
  if (import.meta.env.DEV) {
    console.error(error, context);
  }
}

/**
 * Report a warning to Sentry without throwing an exception
 * 
 * @param {Error|string} warning - The warning object or message
 * @param {Object} [context] - Additional context information
 */
export function reportWarning(warning, context = {}) {
  reportError(warning, context, "warning");
}

/**
 * Report info to Sentry without throwing an exception
 * 
 * @param {string} message - The info message
 * @param {Object} [context] - Additional context information
 */
export function reportInfo(message, context = {}) {
  reportError(message, context, "info");
}